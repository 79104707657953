import * as React from 'react'
import Typography from '@mui/material/Typography'

import footer from '../../../../Assets/footer.svg'

function FooterComponent() {
  return (
    <div
      style={{
        padding: '40px 0px 40px 0px',
        height: '240px',
        flexDirection: 'column',
        gap: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `transparent url(${footer}) no-repeat center`,
        backgroundSize: 'cover',
        overflow: 'visible',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '537px',
          gap: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant='h4' style={{ fontFamily: 'Mars-Centra-Bold' }}>
            How to
          </Typography>
          <Typography
            variant='h4'
            style={{
              fontFamily: 'Mars-Centra-Bold',
              color: 'rgba(214, 66, 0, 1)',
            }}
          >
            Get Involved
          </Typography>
        </div>
        <div
          style={{
            direction: 'flex',
            flexDirection: 'column',
            gap: '24px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            align='center'
            style={{ fontFamily: 'Mars-Centra', color: '#3C3C3C' }}
          >
            NWfG is about meaningful societal impact. Every step innovations can
            take to embed wellbeing moves us one step closer to creating 30B
            moments of wellbeing.{' '}
            <a
              href='https://wellbeingforgenerations.com/2030-roadmap'
              target='_blank'
              rel='noreferrer'
              style={{
                fontFamily: 'Mars-Centra',
                color: '#0000A0',
                textDecoration: 'none',
              }}
            >
              <strong>Click here</strong>
            </a>{' '}
            to view our 2030 Impact Roadmap.
          </Typography>
          <Typography
            style={{
              fontFamily: 'Mars-Centra',
              marginTop: '24px',
              color: '#3C3C3C',
            }}
            align='center'
          >
            Questions? Contact{' '}
            <a href='mailto:avani.sanghvi@effem.com'>
              <strong style={{ color: '#0000A0' }}>Avani Sanghvi</strong>
            </a>{' '}
            for questions about Welby.
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default FooterComponent
