import { useEffect } from 'react'
import { useMsal } from '@azure/msal-react'

const LoginPage = () => {
  const { accounts, instance } = useMsal()

  console.log(accounts?.username)
  const handleSubmit = async (resp) => {
    if (!resp) {
      try {
        const loginResponse = await instance.loginRedirect({
          scopes: ['user.read'],
        })
        console.log(loginResponse)
      } catch (error) {
        console.log('Error during login:', error)
      }
    }
  }

  useEffect(() => {
    instance.handleRedirectPromise().then((resp) => handleSubmit(resp))
  }, [])

  return null
}

export default LoginPage
