import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  projects: [],
  ownerTableData: [],
  loading: false,
  sharedTableData: [],
  activityLogData: [],
  projectId: undefined,
  isProposition: false,
  sharedEmails: [],
  auth: {
    username: localStorage.getItem('username'),
  },
  isLoader: false
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true
    },
    unsetLoading: (state) => {
      state.loading = false
    },
    setIsLoader: (state, action) => {
      state.isLoader = action.payload
    },
    getProjects: (state, action) => {
      console.log(action.payload)
      state.projects = [...action.payload]
      state.loading = false
    },
    activityLogDataAction: (state, action) => {
      state.activityLogData = [...action.payload]
    },
    setProjectId: (state, action) => {
      console.log('slice: ', action.payload)
      state.projectId = action.payload
    },
    setIsProposition: (state, action) => {
      state.isProposition = action.payload
    },
    getEmails: (state, action) => {
      state.sharedEmails = [...action.payload]
    },
    setUserName: (state, action) => {
      state.auth.username = action.payload
    },
    setTableData: (state, action) => {
      let ownerChildData = []
      let sharedChildData = []
      const ownerData = action?.payload?.filter(
        (item) => item?.permission === 'owner'
      )
      const sharedData = action?.payload?.filter(
        (item) => item?.permission === 'shared'
      )
      ownerData?.forEach((data) => {
        if (data?.poc && data?.poc?.length > 0) {
          ownerChildData = [...ownerChildData, ...data?.poc]
        }
        if (data?.proposition && data?.proposition?.length > 0) {
          ownerChildData = [...ownerChildData, ...data?.proposition]
        }
      })
      state.ownerTableData = [
        ...ownerData.map((item) => ({
          id: item?.project_id,
          tableData: { isTreeExpanded: false },
          ...item,
        })),
        ...ownerChildData,
      ]
      sharedData?.forEach((data) => {
        if (data?.poc && data?.poc?.length > 0) {
          sharedChildData = [...sharedChildData, ...data?.poc]
        }
        if (data?.proposition && data?.proposition?.length > 0) {
          sharedChildData = [...sharedChildData, ...data?.proposition]
        }
      })
      state.sharedTableData = [
        ...sharedData?.map((item) => ({ id: item?.project_id, ...item })),
        ...sharedChildData,
      ]
      state.loading = false
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  getProjects,
  setLoading,
  unsetLoading,
  setIsLoader,
  activityLogDataAction,
  setTableData,
  setProjectId,
  setIsProposition,
  setUserName,
  getEmails,
} = projectSlice.actions

export default projectSlice.reducer
