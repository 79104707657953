import { createTheme, styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

export const defaultTheme = createTheme({
  typography: {
    fontFamily: 'Mars-Centra, Mars-Centra-Bold, sans-serif',
  },
})

export const PrimaryButton = styled(Button)((props) => ({
  fontFamily: 'Mars-Centra',
  padding: '8px 16px 8px 16px',
  borderRadius: '4px',
  background: 'rgba(0, 0, 160, 1)',
  color: 'white',
  height: '32px',
  textTransform: 'none',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '16px',
}))

export const OutlinedButton = styled(Button)((props) => ({
  fontFamily: 'Mars-Centra',
  padding: '8px 16px 8px 16px',
  borderRadius: '4px',
  gap: '4px',
  background: 'white',
  color: 'rgba(0, 0, 160, 1)',
  border: '1px solid rgba(0, 0, 160, 1)',
  textTransform: 'none',
  height: '32px',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '16px',
}))
