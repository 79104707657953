import React from 'react'
import Typography from '@mui/material/Typography'

import arrow1 from '../../../../Assets/arrow_1.svg'
import arrow2 from '../../../../Assets/arrow_2.svg'
import arrow3 from '../../../../Assets/arrow_3.svg'
import arrow4 from '../../../../Assets/arrow_4.svg'
import arrow5 from '../../../../Assets/Arrow 5.svg'

import {
  GridContentCard1,
  GridContentCard2,
  GridContentCard3,
  TitleCard,
} from '../Components/Cards'

import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setIsProposition } from '../../../Redux/slices/projectsSlice'
import { Button } from '@mui/material'

function MissionSection(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const id = window.location.href.split('/').pop()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: '80px 56px 80px 56px',
        gap: '40px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <Typography variant='h5' style={{ fontFamily: 'Mars-Centra-Bold' }}>
          First time using Welby?
        </Typography>
        <Typography style={{color: "#262626", fontWeight: "400", fontFamily: "Mars-Centra"}}>
          Let’s get better acquainted with how this works!<br/>
          For more instructions, refer to <a style={{color: "#0000A0", fontWeight: "700"}} href={"https://team.effem.com/:f:/s/WELBYResources/EpNA1tlMcalLnjSBb0Xd0awB5oG11_CsSQIGMW_j8YYZnQ?e=SGlm0v"} target='_blank'
              rel='noreferrer'>Welby Resources.</a>
        </Typography>
      </div>

      <div style={{ gap: '24px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '40px',
            height: '150px',
            zIndex: 2,
            position: 'relative',
          }}
        >
          <div style={{ position: 'absolute', top: '35px', right: '24%' }}>
            <img src={arrow1} alt='Overlay Image' />
          </div>
          <div style={{ paddingTop: '16px' }}>
            <TitleCard
              title={'Start of a new innovation project'}
              bullet={'1'}
              bg={''}
              bgColor={'rgba(250, 250, 250, 1)'}
              fontColor={'black'}
              border={'2px dashed rgba(119, 119, 119, 0.5)'}
            />
          </div>
          <TitleCard
            title={'Welby Initial Concept Assessment'}
            bullet={'2'}
            bg={'rgba(254, 208, 178, 1)'}
            bgColor={'white'}
            fontColor={'rgba(255, 100, 0, 1)'}
          />
        </div>
        <GridContentCard1 />

        <div
          style={{
            paddingTop: '24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '48px',
            height: '150px',
            zIndex: 2,
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '-28px',
              left: '270px',
              width: '104.295px',
              height: '111.589px',
            }}
          >
            <img src={arrow2} alt='Overlay Image' />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '60px',
              right: '330px',
              width: '92.127px',
              height: '111.59px',
            }}
          >
            <img src={arrow3} alt='Overlay Image' />
          </div>
          <div style={{ paddingTop: '24px' }}>
            <TitleCard
              title={'Develop Concepts'}
              bullet={'3'}
              bg={''}
              bgColor={'rgba(250, 250, 250, 1)'}
              fontColor={'black'}
              border={'2px dashed rgba(119, 119, 119, 0.5)'}
            />
          </div>
          <TitleCard
            title={'Welby PoC Assessment'}
            bullet={'4'}
            bg={'rgba(128, 207, 245, 1)'}
            bgColor={'white'}
            fontColor={'rgba(0, 123, 182, 1)'}
          />
        </div>
        <GridContentCard2 />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '24px',
            gap: '48px',
            height: '150px',
            zIndex: 2,
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: '22%',
              top: '-70.069px',
              width: '100.285px',
              height: '121.473px',
            }}
          >
            <img src={arrow4} alt='Overlay Image' />
          </div>
          <div
            style={{
              position: 'absolute',
              right: '29.5%',
              top: '47px',
              width: '92.127px',
              height: '121.59px',
            }}
          >
            <img src={arrow5} alt='Overlay Image' />
          </div>
          <div style={{ paddingTop: '24px' }}>
            <TitleCard
              title={'Identify Leading Concept'}
              bullet={'5'}
              bg={''}
              bgColor={'rgba(250, 250, 250, 1)'}
              fontColor={'black'}
              border={'2px dashed rgba(119, 119, 119, 0.5)'}
            />
          </div>
          <TitleCard
            title={'Welby PoS Assessment'}
            bullet={'6'}
            bg={'rgba(243, 208, 128, 1)'}
            bgColor={'white'}
            fontColor={'rgba(230, 160, 0, 1)'}
          />
        </div>
        <GridContentCard3 />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            paddingTop: '24px',
            alignItems: 'center',
            marginTop: '16px',
          }}
        >
          <Typography variant='h6' style={{ fontFamily: 'Mars-Centra-Bold' }}>
            Ready to test your innovation?
          </Typography>
          <Button
            data-testid='start-new-button3'
            variant='contained'
            style={{
              padding: '8px 16px 8px 16px',
              borderRadius: '4px',
              height: '32px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '16px',
              textTransform: 'capitalize',
              color: 'white',
              opacity: '0.800000011920929',
              background: '#0000A0',
              fontFamily: 'Mars-Centra',
              fontSize: `${window.screen.width < 1024 ? '12px' : '14px'}`,
            }}
            onClick={() => {
              if (
                pathname === `/proposition/result/${id}` ||
                pathname === `/poc/result/${id}`
              ) {
                navigate('/dashboard')
                dispatch(setIsProposition(false))
              } else {
                navigate('/proposition')
                dispatch(setIsProposition(true))
              }
            }}
          >
            Start a New Assessment
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MissionSection
