import {
  activityLogDataAction,
  getProjects,
  setIsLoader,
  setTableData,
  unsetLoading,
} from '../Redux/slices/projectsSlice'
import client from './client'

export const getProjectData = () => {
  return (dispatch) => {
    client
      .get(`/survey/v1/dashboard/`)
      .then((response) => {
        dispatch(getProjects(response.data))
        dispatch(setTableData(response.data))
        dispatch(setIsLoader(false))
      })
      .catch((error) => {
        console.log(error)
        dispatch(unsetLoading())
      })
  }
}

export const createNewProject = (data, callback) => {
  
  return (dispatch) => {
    client
      .post(`/survey/v1/projects/`, data)
      .then((response) => {
        const status = 's'
        callback(status, response.data)
      })
      .catch((error) => {
        console.log(error)
        callback(error.response.status, error.response.data)
      })
  }
}

export const shareProject = (data, callback) => {
  return (dispatch) => {
    client
      .post(`/survey/v1/project-share/`, data)
      .then((response) => {
        const status = 's'
        callback(status)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const getSharedEmails = (id, callback) => {
  return (dispatch) => {
    client
      .get(`/survey/v1/shareduser/${id}/`)
      .then((response) => {
        const status = 's'
        callback(status, response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const unShareProject = (data, callback) => {
  return (dispatch) => {
    client
      .put(`/survey/v1/project-share/unshare-a-project/`, data)
      .then((response) => {
        const status = 's'
        callback(status)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const deleteProject = (data, id, callback) => {
  return (dispatch) => {
    client
      .put(`/survey/v1/projects/${id}/`, data)
      .then((response) => {
        const status = 's'
        callback(status)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const renameProject = (data, id, callback) => {
  return (dispatch) => {
    client
      .put(`/survey/v1/projects/${id}/`, data)
      .then((response) => {
        const status = 's'
        callback(status, '')
      })
      .catch((error) => {
        callback(error.response.status, error.response.data)
      })
  }
}

export const favoritingProject = (data, callback) => {
  return (dispatch) => {
    client
      .post(`/survey/v1/favourites/update-by-field/`, data)
      .then((response) => {
        const status = 's'
        callback(status)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const sendSSODataToDjango = (data) => {
  
  client
    .post(`/auth/v1/sso/`, data)
    .then((data) => {
      console.log(data)
    })
    .catch((error) => {
      console.log('Error:', error)
    })
}

export const getActivityLogData = (id) => {
  return (dispatch) => {
    client
      .get(`/survey/v1/activities/get_by_project/?id=${id}`, {})
      .then((response) => {
        dispatch(activityLogDataAction(response.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
