/* eslint-disable no-param-reassign */
import axios from 'axios'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from '../authentication/msalConfig'

const msalInstance = new PublicClientApplication(msalConfig)

const client = axios.create({
  baseURL: `${process.env.REACT_APP_DAJNGO_URL}`,
  responseType: 'json',
})

client.interceptors.request.use(
  async (config) => {
    config.headers = config.headers ?? {}
    const oid = localStorage.getItem('oid')
    config.headers['Content-Type'] = 'application/json'
    config.headers['Oid'] = oid
    const token = await refreshAccessToken()
    if (token) {
      localStorage.setItem('idToken', token)
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.config && error.response && error.response.status === 401) {
      localStorage.clear()
      sessionStorage.clear()
      msalInstance.logoutRedirect()
    }
    return Promise.reject(error)
  }
)

const refreshAccessToken = async () => {
  const account = msalInstance.getAllAccounts()
  try {
    const token = await msalInstance.acquireTokenSilent({
      scopes: ['user.read'],
      account: account[0],
    })
    return token.accessToken
  } catch (error) {
    localStorage.clear()
    sessionStorage.clear()
    return msalInstance.logoutRedirect()
  }
}
export default client
