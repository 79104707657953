import LoginPage from './login/LoginPage'
import React from 'react'
import { useIsAuthenticated } from '@azure/msal-react'
import { useMatomo } from '@datapunt/matomo-tracker-react'

function MsalGuard(props) {
  const isAuthenticated = useIsAuthenticated()
  const { trackPageView } = useMatomo()
  React.useEffect(() => {
    if (isAuthenticated) {
      trackPageView()
    }
  }, [window.location.href])
  return (
    <>
      {isAuthenticated ? (
        <>{props.children}</>
      ) : (
        <LoginPage setIsAuthenticated />
      )}
    </>
  )
}
export default MsalGuard
