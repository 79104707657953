import React, { Suspense } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'

import { msalConfig } from './authentication/msalConfig'
import LandingPage from './authentication/Landing Page/LandingPage'
import MsalGuard from './authentication/MsalGuard'
import { CircularProgress } from '@mui/material'

const msalInstance = new PublicClientApplication(msalConfig)

const ProjectViews = React.lazy(() => import('./ProjectViews'))
const ProjectPage = React.lazy(() => import('./ProjectPage'))
const Header = React.lazy(() => import('./Header'))
const PropositionPage = React.lazy(() =>
  import('./Preposition/PropositionPage')
)
const PropositionResultPage = React.lazy(() =>
  import('./Preposition/PropositionItems/PropositionResultPage')
)
const PocResultPage = React.lazy(() => import('./Poc/PocItems/PocResultPage'))
const PocPage = React.lazy(() => import('./Poc/PocPage'))

function Layout(props) {
  const location = useLocation()

  return (
    <MsalProvider instance={props.msalInstance}>
      <Suspense
        fallback={
          <div
            style={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </div>
        }
      >
        <MsalGuard>
          {location['pathname'] !== '/' && (
            <div className='header'>
              <Header />
            </div>
          )}
          <Routes>
            <Route exact path='/' Component={LandingPage} />
            <Route exact path='/dashboard' Component={ProjectViews} />
            <Route exact path='/project/:id' Component={ProjectPage} />
            <Route exact path='/proposition' Component={PropositionPage} />
            <Route
              exact
              path='/proposition/result/:id'
              Component={PropositionResultPage}
            />
            <Route exact path='/poc' Component={PocPage} />
            <Route exact path='/poc/result/:id' Component={PocResultPage} />
          </Routes>
        </MsalGuard>
      </Suspense>
    </MsalProvider>
  )
}

export default Layout
