import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  physicalWellbeing: [],
  socialWellbeing: [],
  emotionalWellbeing: [],
  physicalCheckbox: false,
  emotionalCheckbox: false,
  socialCheckbox: false,
}

export const PSEwellbeingSlice = createSlice({
  name: 'PSEwellbeing',
  initialState,
  reducers: {
    setPhysicalwellbeing(state, action) {
      state.physicalWellbeing = action.payload
    },
    setSocialwellbeing(state, action) {
      state.socialWellbeing = action.payload
    },
    setEmotionalwellbeing(state, action) {
      state.emotionalWellbeing = action.payload
    },
    ResetWellbeing(state) {
      state.emotionalWellbeing = []
      state.physicalWellbeing = []
      state.socialWellbeing = []
      state.physicalCheckbox = false
      state.emotionalCheckbox = false
      state.socialCheckbox = false
    },
    setPhysicalCheckbox(state, action) {
      state.physicalCheckbox = action.payload
    },
    setSocialCheckbox(state, action) {
      state.socialCheckbox = action.payload
    },
    setEmotionalCheckbox(state, action) {
      state.emotionalCheckbox = action.payload
    },
  },
})

export const {
  setEmotionalwellbeing,
  setSocialwellbeing,
  ResetWellbeing,
  setPhysicalwellbeing,
  setPhysicalCheckbox,
  setSocialCheckbox,
  setEmotionalCheckbox,
} = PSEwellbeingSlice.actions

export default PSEwellbeingSlice.reducer
