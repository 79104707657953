import { Avatar, Box, Typography } from '@mui/material'
import { PrimaryButton } from '../Components/themes'
import demoImg from '../../../../Assets/app.png'
import hoverText from '../../../../Assets/hover_text.svg'
import imgKangroo from '../../../../Assets/kangroo_icon.svg'
import imgKangrooRight from '../../../../Assets/kangroo_right.svg'
import rectangle from '../../../../Assets/rectangle.svg'
import CircleImageOverlayComponent from './circle-img'
import imgGrid1 from '../../../../Assets/grid_img_1.svg'
import imgGrid5 from '../../../../Assets/grid_img_5.svg'
import imgGrid4 from '../../../../Assets/grid_img_4.svg'
import imgKangrooStar from '../../../../Assets/kangroo_star.svg'

import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setIsProposition } from '../../../Redux/slices/projectsSlice'

export function MainCard() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const id = window.location.href.split('/').pop()

  return (
    <div
      style={{
        display: 'flex',
        marginLeft: '56px',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        background: 'transparent',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          position: 'relative',
          top: '75px',
          gap: '20px',
        }}
      >
        <Typography
          style={{
            color: 'var(--neutral-neutral-1000, #262626)',
            fontFamily: 'Mars-Centra-Bold',
            fontSize: '48px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            position: 'relative',
            zIndex: '2'
          }}
        >
          Curious how you might
        </Typography>
        <Typography
          style={{
            color: 'var(--orange-orange-700, #D64200)',
            fontFamily: 'Mars-Centra-Bold',
            fontSize: '48px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            position: 'relative',
            zIndex: '4'
          }}
        >
          Nurture Wellbeing
          <span
            style={{
              color: 'var(--neutral-neutral-1000, #262626)',
              fontFamily: 'Mars-Centra-Bold',
              fontSize: '48px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
            }}
          >
            ?
          </span>
        </Typography>

        <div direction={'row'}>
          <PrimaryButton
            data-testid='start-new-button'
            style={{
              marginTop: '16px',
              padding: '8px 16px 8px 16px',
              borderRadius: '4px',
              height: '32px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '16px',
              textTransform: 'capitalize',
              color: 'white',
              background: '#0000A0',
              fontFamily: 'Mars-Centra',
              fontSize: `${window.screen.width < 1024 ? '12px' : '14px'}`,
            }}
            variant='contained'
            onClick={() => {
              if (
                pathname === `/proposition/result/${id}` ||
                pathname === `/poc/result/${id}`
              ) {
                navigate('/dashboard')
                dispatch(setIsProposition(false))
              } else {
                navigate('/proposition')
                dispatch(setIsProposition(true))
              }
            }}
          >
            Start New Assessment
          </PrimaryButton>
        </div>
      </div>
      <div>
        <img
          src={demoImg}
          alt='Overlay Image'
          width={'630px'}
          height={'450px'}
          style={{
            position: 'relative',
            top: '115px',
            right: '30px',
            border: '10px solid rgba(0, 158, 234, 1)',
            borderRadius: '25px',
            zIndex: 3,
          }}
        />
        <img
          src={hoverText}
          alt='Overlay Image'
          style={{
            position: 'absolute',
            top: '380px',
            right: '640px',
            zIndex: 2,
          }}
        />
        <img
          src={imgKangroo}
          alt='Overlay Image'
          width={'225px'}
          height={'300px'}
          style={{
            position: 'absolute',
            top: '333.5px',
            right: '470px',
            zIndex: 3,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '-354.61px',
            right: '-134.61px',
            backgroundColor: 'rgba(243, 208, 128, 1)',
            borderRadius: '453.36px',
            height: '839.85px',
            width: '839.85px',
            zIndex: 1,
            overflow: 'hidden',
          }}
        />
      </div>
    </div>
  )
}

export function MainContentCard() {
  return (
    <div
      style={{
        background: '#f2fbff',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ paddingTop: '48px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '48px 56px',
            direction: 'row',
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '530px',
              alignItems: 'center',
            }}
          >
            <img
              src={imgKangrooRight}
              alt='Overlay Image'
              width={'249.9px'}
              height={'337.27px'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '632px',
              gap: '32px',
            }}
          >
            <Typography variant='h4' style={{ fontFamily: 'Mars-Centra-Bold' }}>
              Our Mission
            </Typography>
            <Typography style={{ fontFamily: 'Mars-Centra' }}>
              My mission is to be your coach as you explore ways to nurture
              wellbeing – from designing in behavior prompts, to ensuring you’re
              up to date on the latest guidelines and commitments - in your
              project. As we move ahead together, you will get:
            </Typography>
            <Typography style={{ fontFamily: 'Mars-Centra' }}>
              <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                Learning opportunities
              </strong>{' '}
              to dive deeper into Wellbeing and the science behind it.
            </Typography>
            <Typography style={{ fontFamily: 'Mars-Centra' }}>
              <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                Ways to assess a project’s
              </strong>{' '}
              contribution to our{' '}
              <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                Wellbeing Commitments
              </strong>{' '}
              and{' '}
              <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                compliance
              </strong>{' '}
              to the portfolio guidelines
            </Typography>
            <Typography style={{ fontFamily: 'Mars-Centra' }}>
              <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                Key choices and trade offs
              </strong>{' '}
              to enable decision discussions with leadership
            </Typography>
            <Typography style={{ fontFamily: 'Mars-Centra' }}>
              To learn more about{' '}
              <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                Nurturing Wellbeing for Generations (NWfG),{' '}
              </strong>
              <a
                data-testid='click-here-1'
                href='https://wellbeingforgenerations.com/'
                target='_blank'
                rel='noreferrer'
              >
                <strong
                  style={{
                    fontFamily: 'Mars-Centra-Bold',
                    cursor: 'pointer',
                    color: '#0000A0',
                  }}
                >
                  click here.
                </strong>
              </a>
            </Typography>
          </div>
        </div>
      </div>
      <img src={rectangle} alt='Overlay Image' />
    </div>
  )
}

export function ContentCard() {
  return (
    <div style={{ backgroundColor: 'rgba(255, 240, 248, 1)' }}>
      <Box padding={'48px 56px 48px 56px'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            // padding={"56px 48px 56px 48px"}
            direction: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '632px',
              gap: '32px',
            }}
          >
            <Typography variant='h4' style={{ fontFamily: 'Mars-Centra-Bold' }}>
              Why does Welby focus on behavior prompts?
            </Typography>
            <Typography style={{ fontFamily: 'Mars-Centra' }}>
              Research indicates that{' '}
              <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                small changes in mindset and wellbeing-related behaviors can
                lead to improvements in people’s health and lives—
              </strong>
              and over time has a positive knock-on effect.
            </Typography>
            <Typography style={{ fontFamily: 'Mars-Centra' }}>
              This is where our brands and innovations can play a role by
              nudging wellbeing behaviors across consumer touchpoints (products,
              packaging, messaging, partnerships etc.) to drive small changes in
              consumers’ lives.
            </Typography>
            <Typography style={{ fontFamily: 'Mars-Centra' }}>
              Welby is here to help by providing options through behavior
              prompts across physical, emotional and social wellbeing that you
              can embed in every innovation—every small change counts!
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '696px',
              alignItems: 'center',
            }}
          >
            <img
              src={imgKangrooStar}
              alt='Overlay Image'
              width={'249.9px'}
              height={'337.27px'}
            />
          </div>
        </div>
      </Box>
    </div>
  )
}

export function GridContentCard1() {
  return (
    <div
      style={{
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'rgba(255, 234, 221, 1)',
        borderRadius: '60px',
        padding: '48px 56px 48px 56px',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '56px',
      }}
    >
      <div style={{ width: '556px', alignItems: 'center' }}>
        <CircleImageOverlayComponent
          imgSrc={imgGrid1}
          circleColor={'rgba(255, 100, 0, 1)'}
        />
      </div>
      <div style={{ width: '556px', gap: '32px' }}>
        <Typography style={{ fontFamily: 'Mars-Centra' }}>
          A new Pitch?! Use Welby’s{' '}
          <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
            initial concept assessment
          </strong>{' '}
          to explore the wellbeing dimensions and behavior prompts and identify
          wellbeing commitments and guidelines you need to meet.{' '}
          <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
            You will select Welby behaviors to shape your concept
          </strong>
          , and your initial assessment output could be part of your pitch!
        </Typography>
      </div>
    </div>
  )
}

export function GridContentCard2() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'rgba(242, 251, 255, 1)',
        borderRadius: '60px',
        padding: '48px 56px 48px 56px',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '56px',
        marginTop: '34px',
      }}
    >
      <div style={{ width: '556px', gap: '32px' }}>
        <Typography style={{ fontFamily: 'Mars-Centra' }}>
          So many concepts! Use{' '}
          <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
            Welby PoC/PoS assessment
          </strong>{' '}
          for each to gauge compliance & inform trade-off choices between
          concepts through the wellbeing lens. Here you’ll get a{' '}
          <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
            Welby score!
          </strong>{' '}
          Include this as you bring the concepts to the ILT.
        </Typography>
      </div>
      <div style={{ width: '556px', alignItems: 'center' }}>
        <CircleImageOverlayComponent
          imgSrc={imgGrid5}
          circleColor={'rgba(0, 158, 234, 1)'}
        />
      </div>
    </div>
  )
}

export function GridContentCard3() {
  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: 'rgba(253, 243, 220, 1)',
        borderRadius: '60px',
        padding: '48px 56px 48px 56px',
        alignItems: 'center',
        justifyContent: 'space-between',
        direction: 'row',
        gap: '56px',
        marginTop: '34px',
      }}
    >
      <div style={{ width: '556px', alignItems: 'center' }}>
        <CircleImageOverlayComponent
          imgSrc={imgGrid4}
          circleColor={'rgba(230, 160, 0, 1)'}
        />
      </div>
      <div style={{ width: '556px', alignItems: 'center' }}>
        <Typography style={{ fontFamily: 'Mars-Centra' }}>
          Validate your scale concept using the Welby PoC/PoS assessment.
        </Typography>
      </div>
    </div>
  )
}

export function TitleCard({
  title,
  bullet,
  bg,
  bgColor,
  fontColor,
  border = '',
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: bg,
          border: border,
          borderRadius: '40px',
          padding: '16px 40px 16px 40px',
          alignItems: 'center',
          direction: 'row',
          gap: '24px',
          display: 'inline-flex',
        }}
      >
        <Avatar
          sx={{
            bgcolor: bgColor,
            color: fontColor,
            fontFamily: 'Mars-Centra-Bold',
          }}
        >
          {bullet}
        </Avatar>
        <Typography variant='h6' style={{ fontFamily: 'Mars-Centra-Bold' }}>
          {title}
        </Typography>
      </div>
    </div>
  )
}
