import React from 'react'
import Typography from '@mui/material/Typography'

import KangrooGrid1 from '../../../../Assets/kangroo_grid_1.svg'
import KangrooGrid2 from '../../../../Assets/kangroo_grid_2.svg'
import KangrooGrid3 from '../../../../Assets/kangroo_grid_3.svg'
import KangrooGrid4 from '../../../../Assets/kangroo_grid_4.svg'

import star1 from '../../../../Assets/star_1.svg'
import star2 from '../../../../Assets/star_2.svg'
import star3 from '../../../../Assets/star_3.svg'

function TypoGrid({ title, main, content, content2 }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'flex-start',
      }}
    >
      <Typography variant='h6' style={{ fontFamily: 'Mars-Centra-Bold' }}>
        {title}
      </Typography>
      <Typography style={{ fontFamily: 'Mars-Centra' }}>{main}</Typography>
      <Typography style={{ fontFamily: 'Mars-Centra' }}>{content}</Typography>
      <Typography style={{ fontFamily: 'Mars-Centra' }}>{content2}</Typography>
    </div>
  )
}

function ImgContent({ imgSrc, content }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '273px',
        height: '307.01px',
        alignItems: 'center',
        gap: '16px',
        padding: '24px 0px 24px 0px',
      }}
    >
      <img src={imgSrc} alt='Overlay Image' />
      {content}
    </div>
  )
}

function ProgressBar({ content, bg, bg1, bg2, bg3 }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '589px',
        padding: '24px',
        gap: '16px',
        backgroundColor: bg,
        borderRadius: '16px',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        <div
          style={{
            width: '120px',
            height: '8px',
            backgroundColor: bg1,
            borderRadius: '8px',
          }}
        ></div>
        <div
          style={{
            width: '120px',
            height: '8px',
            backgroundColor: bg2,
            borderRadius: '8px',
          }}
        ></div>
        <div
          style={{
            width: '120px',
            height: '8px',
            backgroundColor: bg3,
            borderRadius: '8px',
          }}
        ></div>
      </div>
      {content}
    </div>
  )
}

function RatingBar({ content, starIcon }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '589px',
        padding: '24px',
        gap: '8px',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderRadius: '16px',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography style={{ fontFamily: 'Mars-Centra-Bold' }}>
        {content}
      </Typography>
      <div direction={'row'} gap={'8px'}>
        {/* <Rating name="half-rating" defaultValue={3} max={3} /> */}
        <img src={starIcon} alt='Overlay Image' />
      </div>
    </div>
  )
}

function ScoreSection(props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '72px 56px 48px 56px',
        gap: '32px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <Typography variant='h5' style={{ fontFamily: 'Mars-Centra-Bold' }}>
          What does the Welby assessment tell me?
        </Typography>
        <Typography style={{ fontFamily: 'Mars-Centra' }}>
          An innovation will receive two scores at the PoC and PoS stages – a
          “Commitment Status” and a “Wellbeing Behavior Prompt Score.”
        </Typography>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'white',
          padding: '24px',
          justifyContent: 'space-between',
          borderRadius: '16px',
          gap: '64px',
        }}
      >
        <TypoGrid
          title='Commitment Status'
          main='Your commitment status measures the innovation’s compliance with our defined business commitments.'
          content='Commitment Status is a traditional stoplight status. Here’s what each color signifies:'
        />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <ProgressBar
            content={
              <Typography style={{ fontFamily: 'Mars-Centra' }}>
                <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                  Red:{' '}
                </strong>
                One or more required guidelines have{' '}
                <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                  not been met
                </strong>
              </Typography>
            }
            bg='rgba(255, 234, 234, 1)'
            bg1={'rgba(184, 3, 2, 1)'}
            bg2={'rgba(227, 227, 227, 1)'}
            bg3={'rgba(227, 227, 227, 1)'}
          />
          <ProgressBar
            content={
              <Typography style={{ fontFamily: 'Mars-Centra' }}>
                <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                  Yellow:{' '}
                </strong>
                One or more required guidelines are{' '}
                <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                  in progress,
                </strong>{' '}
                but have not been fully met or committed to
              </Typography>
            }
            bg={'rgba(253, 243, 220, 1)'}
            bg1={'rgba(230, 160, 0, 1)'}
            bg2={'rgba(230, 160, 0, 1)'}
            bg3={'rgba(227, 227, 227, 1)'}
          />
          <ProgressBar
            content={
              <Typography style={{ fontFamily: 'Mars-Centra' }}>
                <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                  Green:{' '}
                </strong>
                All required guidelines{' '}
                <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                  have been met
                </strong>
              </Typography>
            }
            bg={'rgba(242, 255, 248, 1)'}
            bg1={'rgba(15, 176, 82, 1)'}
            bg2={'rgba(15, 176, 82, 1)'}
            bg3={'rgba(15, 176, 82, 1)'}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'white',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px',
          borderRadius: '16px',
          gap: '64px',
        }}
      >
        <TypoGrid
          title='Wellbeing Behavior Prompt Scores'
          main='Your Wellbeing Behavior Prompt Score is calculated across physical, social, and emotional dimensions.'
          content='An innovation receives one star for every two behavior prompts committed to in a single wellbeing dimension. You can earn a maximum of three stars in each dimension.'
          content2='And remember! Behavior prompts can be included across a range of consumer touchpoints such as products, packaging, on-pack messaging, off-pack messaging like websites, brand campaigns, partnerships etc.'
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          <RatingBar content={'Physical Wellbeing'} starIcon={star1} />
          <RatingBar content={'Social Wellbeing'} starIcon={star2} />
          <RatingBar content={'Emotional Wellbeing'} starIcon={star3} />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: '24px',
          borderRadius: '16px',
          gap: '16px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant='h6' style={{ fontFamily: 'Mars-Centra-Bold' }}>
            Welby Scores
          </Typography>
          <Typography style={{ fontFamily: 'Mars-Centra' }}>
            Finally, an innovation’s Commitment Status and Wellbeing Behavior
            Prompt Score together determine its overall Welby Score!
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <ImgContent
            imgSrc={KangrooGrid1}
            content={
              <Typography align='center' style={{ fontFamily: 'Mars-Centra' }}>
                Has started on their wellbeing journey and has{' '}
                <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                  plenty of room to make impact
                </strong>
              </Typography>
            }
          />
          <ImgContent
            imgSrc={KangrooGrid2}
            content={
              <Typography align='center' style={{ fontFamily: 'Mars-Centra' }}>
                Meets all wellbeing{' '}
                <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                  commitments and guidelines
                </strong>
              </Typography>
            }
          />
          <ImgContent
            imgSrc={KangrooGrid3}
            content={
              <Typography align='center' style={{ fontFamily: 'Mars-Centra' }}>
                Leans into wellbeing impact by embedding behaviors{' '}
                <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                  across multiple dimensions
                </strong>
              </Typography>
            }
          />
          <ImgContent
            imgSrc={KangrooGrid4}
            content={
              <Typography align='center' style={{ fontFamily: 'Mars-Centra' }}>
                Elevates wellbeing by embedding consumer behaviors{' '}
                <strong style={{ fontFamily: 'Mars-Centra-Bold' }}>
                  across all touchpoints.
                </strong>
              </Typography>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default ScoreSection
