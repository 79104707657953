import { createSlice } from '@reduxjs/toolkit'
const fetchPocProductQuestionsPayload = {
  //   poc_product_questions: [
  //     {
  //         "question_id": 1,
  //         "question": "Are you designing a Product or Experience?",
  //         "question_metadata": "",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "innovation": "Product",
  //                 "option_metadata": "Any innovation that involves a product and brand-related communications",
  //                 "next_question_id": 2
  //             },
  //             {
  //                 "innovation": "Experience",
  //                 "option_metadata": "Anything that does not involve a product innovation (e.g. a service, or a brand campaign on an existing product etc.)",
  //                 "next_question_id": 3
  //             }
  //         ],
  //         "avatar_name": "avatar_1",
  //         "avatar_text": "Hi! I’m Welby the Wallaby. I’ll be popping in once and while to remind you to take breaks and prioritize your wellbeing!"
  //     },
  //     {
  //         "question_id": 2,
  //         "question": "What type of Product are you?",
  //         "question_metadata": "",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "product": "Treats, Core",
  //                 "option_metadata": "An indulgent product that may have limited nutritional value, and is intended to be consumed in smaller portions, infrequently, and in moderation /nCould serve need states: Enjoy, Connection, Relax",
  //                 "next_question_id": 7
  //             },
  //             {
  //                 "product": "Treats, Reduced Sugar",
  //                 "option_metadata": "Treats with a claimable reduction in sugar /nCould serve need states: Enjoy, Connection, Relax",
  //                 "next_question_id": 8
  //             },
  //             {
  //                 "product": "Snacks, Base",
  //                 "option_metadata": "A food containing at least 1/2 serving of a recommended food with a claimable reduction in sugar, that is consumed between mealtimes. In general, a snack will contain 10% of the total calories of the day /nCould serve need states: Relax, Fuel, Healthier Intent",
  //                 "next_question_id": 9
  //             },
  //             {
  //                 "product": "Snacks, Nutrient Dense",
  //                 "option_metadata": "Foods that provide a meaningful amount of a recommended food group and do not exceed nutrients to limit thresholds or achieve a rating of at least 3.5 stars on the Australian Health Star nutrient profiling model /nCould serve need states: Fuel, Healthier Intent",
  //                 "next_question_id": 10
  //             },
  //             {
  //                 "product": "Gum, Mints, Others",
  //                 "option_metadata": "Nutritionally insignificant formats like gum, mints, hard candy, etc./nCould serve need states: Relax, Healthier Intent, and other gum specific ones",
  //                 "next_question_id": 11
  //             }
  //         ],
  //         "avatar_name": "avatar_2",
  //         "avatar_text": "Don’t forget to move in your day! Take a quick 60 second stretch break."
  //     },
  //     {
  //         "question_id": 3,
  //         "question": "Is this Experience part of a Global Brand?",
  //         "question_metadata": "",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "experience": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 4
  //             },
  //             {
  //                 "experience": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 6
  //             }
  //         ],
  //         "avatar_name": "",
  //         "avatar_text": ""
  //     },
  //     {
  //         "question_id": 4,
  //         "question": "Has the brand defined an emotional wellbeing focus area as part of the Nurturing Wellbeing for Generations (NWfG) commitment?",
  //         "question_metadata": "Click here for information about the NWfG emotional wellbeing commitments.",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "emotional_wellbeing": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 5
  //             },
  //             {
  //                 "emotional_wellbeing": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 5
  //             },
  //             {
  //                 "emotional_wellbeing": "Unsure / Work in Progress",
  //                 "option_metadata": "",
  //                 "next_question_id": 5
  //             }
  //         ],
  //         "avatar_name": "",
  //         "avatar_text": ""
  //     },
  //     {
  //         "question_id": 5,
  //         "question": "Which Wellbeing Dimension are you hoping to drive impact in through this Experience?",
  //         "question_metadata": "Select all that apply.",
  //         "select_type": "Multi Select",
  //         "options": [
  //             {
  //                 "dimension": "Emotional Wellbeing",
  //                 "option_metadata": "Self awareness of feelings to positively shift behaviors that builds connection (to self, to others, to nature) and overall resilience.",
  //                 "next_question_id": 6
  //             },
  //             {
  //                 "dimension": "Physical Wellbeing",
  //                 "option_metadata": "Essential daily routines – breathing, sleeping, eating, moving – to minimize fatigue or physical stress.",
  //                 "next_question_id": ""
  //             },
  //             {
  //                 "dimension": "Social Wellbeing",
  //                 "option_metadata": "Intentional action to reducing barriers for our communities for products that are nutritious, affordable & accessible as well as sustainably grown & produced",
  //                 "next_question_id": ""
  //             }
  //         ],
  //         "avatar_name": "avatar_3",
  //         "avatar_text": "Take a moment to practice a breathing exercise. Breathing is a wonderful way to feel like you're in control!"
  //     },
  //     {
  //         "question_id": 6,
  //         "question": "Please consider how this experience might impact someone's Emotional Wellbeing?",
  //         "question_metadata": "Please select the emotional wellbeing focus area this experience will prioritize to address your target population’s need (e.g. low self esteem, anxiety, loneliness, stress).",
  //         "select_type": "Multi Select",
  //         "options": [
  //             {
  //                 "focus_area": "Calm",
  //                 "option_metadata": "State of peace of mind, restfulness free from agitation:*Calm and connected*High levels of self - esteem;healthy social relationships",
  //                 "next_question_id": ""
  //             },
  //             {
  //                 "focus_area": "Confidence",
  //                 "option_metadata": "Openness to new experiences & challenges:*Assertive, high - levels of self - efficacy*Negative events viewed as external, temporary, specific",
  //                 "next_question_id": ""
  //             },
  //             {
  //                 "focus_area": "Connection",
  //                 "option_metadata": "Quality social connections:*Sense of securely attached relationships*Increased life satisfaction & happiness(predictor for overall physical health & longevity)",
  //                 "next_question_id": ""
  //             },
  //             {
  //                 "focus_area": "Control",
  //                 "option_metadata": "High state of agency to handle & respond to situations:*Positive outlook on future, feeling calm & collected*Energetic, healthy",
  //                 "next_question_id": ""
  //             }
  //         ],
  //         "avatar_name": "avatar_7",
  //         "avatar_text": "Write down 1-3 things you are grateful for today. /nGratitude can help you feel calm!"
  //     },
  //     {
  //         "question_id": 7,
  //         "question": "Is this product going to be < = 200 kcal per serving?",
  //         "question_metadata": "",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "kcal_per_200_serving": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 16
  //             },
  //             {
  //                 "kcal_per_200_serving": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 12
  //             }
  //         ],
  //         "avatar_name": "",
  //         "avatar_text": ""
  //     },
  //     {
  //         "question_id": 10,
  //         "question": "Is this product going to be < = 250 kcal per serving?",
  //         "question_metadata": "",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "kcal_per_250_serving": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 15
  //             },
  //             {
  //                 "kcal_per_250_serving": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 15
  //             }
  //         ],
  //         "avatar_name": "",
  //         "avatar_text": ""
  //     },
  //     {
  //         "question_id": 11,
  //         "question": "Do you meet the nutritional guidelines per the Product Design Toolkit?",
  //         "question_metadata": "Click here for information about the Product Design Toolkit.",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "nutritional_guidelines": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 22
  //             },
  //             {
  //                 "nutritional_guidelines": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 22
  //             },
  //             {
  //                 "nutritional_guidelines": "Unsure / Work in Progress",
  //                 "option_metadata": "",
  //                 "next_question_id": 22
  //             }
  //         ],
  //         "avatar_name": "avatar_5",
  //         "avatar_text": "Take a minute and send someone you know a quick message! Connections help build resislience!"
  //     },
  //     {
  //         "question_id": 13,
  //         "question": "Is this product going to be <= 25 g sugar per serving?",
  //         "question_metadata": "",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "kcal_per_25_serving": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 17
  //             },
  //             {
  //                 "kcal_per_25_serving": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 17
  //             }
  //         ],
  //         "avatar_name": "avatar_4",
  //         "avatar_text": "Take a moment to practice a breathing exercise. Breathing is a wonderful way to feel like you're in control!"
  //     },
  //     {
  //         "question_id": 15,
  //         "question": "Will you meet the Mars definition of a nutrient dense snack?",
  //         "question_metadata": "",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "nutrient_dense": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 22
  //             },
  //             {
  //                 "nutrient_dense": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 19
  //             }
  //         ],
  //         "avatar_name": "",
  //         "avatar_text": ""
  //     },
  //     {
  //         "question_id": 17,
  //         "question": "Will you have a claimable reduction in sugar (from a referenceable product)?",
  //         "question_metadata": "",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "sugar_reduction": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 22
  //             },
  //             {
  //                 "sugar_reduction": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 22
  //             }
  //         ],
  //         "avatar_name": "avatar_4",
  //         "avatar_text": "Take a moment to practice a breathing exercise. Breathing is a wonderful way to feel like you're in control!"
  //     },
  //     {
  //         "question_id": 19,
  //         "question": "Will you meet one of the following?",
  //         "question_metadata": "",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "nutrient": "Having a Health Star Rating of > 3.5",
  //                 "option_metadata": "",
  //                 "next_question_id": 22
  //             },
  //             {
  //                 "nutrient": "Having a Health Star Rating of > 3.5 AND containing meaningful amount of recommended food",
  //                 "option_metadata": "",
  //                 "next_question_id": 22
  //             },
  //             {
  //                 "nutrient": "None of the above",
  //                 "option_metadata": "",
  //                 "next_question_id": 22
  //             }
  //         ],
  //         "avatar_name": "avatar_4",
  //         "avatar_text": "Take a moment to practice a breathing exercise. Breathing is a wonderful way to feel like you're in control!"
  //     },
  //     {
  //         "question_id": 20,
  //         "question": "Will you have a claimable reduction in sugar (from a referenceable product)?",
  //         "question_metadata": "",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "claimable_reduction": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 21
  //             },
  //             {
  //                 "claimable_reduction": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 21
  //             }
  //         ],
  //         "avatar_name": "",
  //         "avatar_text": ""
  //     },
  //     {
  //         "question_id": 21,
  //         "question": "Are you committed to having a minimum 1/2 serving of a recommended food per serving?",
  //         "question_metadata": "Click here to learn more about recommended foods.",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "recommended_food": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 22
  //             },
  //             {
  //                 "recommended_food": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 22
  //             }
  //         ],
  //         "avatar_name": "avatar_4",
  //         "avatar_text": "Take a moment to practice a breathing exercise. Breathing is a wonderful way to feel like you're in control!"
  //     },
  //     {
  //         "question_id": 22,
  //         "question": "Will you have a portion icon on pack?",
  //         "question_metadata": "Click here for information about portion icons.",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "icon_pack": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 23
  //             },
  //             {
  //                 "icon_pack": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 23
  //             },
  //             {
  //                 "icon_pack": "Unsure / Work in Progress",
  //                 "option_metadata": "",
  //                 "next_question_id": 23
  //             }
  //         ],
  //         "avatar_name": "",
  //         "avatar_text": ""
  //     },
  //     {
  //         "question_id": 23,
  //         "question": "Will you have a GDA label on pack?",
  //         "question_metadata": "",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "gda_label": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 24
  //             },
  //             {
  //                 "gda_label": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 24
  //             },
  //             {
  //                 "gda_label": "Not Applicable",
  //                 "option_metadata": "",
  //                 "next_question_id": 24
  //             }
  //         ],
  //         "avatar_name": "",
  //         "avatar_text": ""
  //     },
  //     {
  //         "question_id": 24,
  //         "question": "Is this proof of concept part of a Global Brand?",
  //         "question_metadata": "",
  //         "select_type": "Single Select",
  //         "options": [
  //             {
  //                 "proof_of_concept": "Yes",
  //                 "option_metadata": "",
  //                 "next_question_id": 4
  //             },
  //             {
  //                 "proof_of_concept": "No",
  //                 "option_metadata": "",
  //                 "next_question_id": 4
  //             }
  //         ],
  //         "avatar_name": "avatar_6",
  //         "avatar_text": "Take a minute and send someone you know a quick message! Connections help build resislience!"
  //     },
  //     {
  //         "question_id": 25,
  //         "question": "Across which consumer touchpoints will the behavior prompts show up?",
  //         "question_metadata": "Select all that apply.",
  //         "select_type": "Multi Select",
  //         "options": [
  //             {
  //                 "behavior_prompt": "Product",
  //                 "option_metadata": "",
  //                 "next_question_id": ""
  //             },
  //             {
  //                 "behavior_prompt": "Pack Format or Structural Design",
  //                 "option_metadata": "e.g. portionable pack",
  //                 "next_question_id": ""
  //             },
  //             {
  //                 "behavior_prompt": "On-pack communication",
  //                 "option_metadata": "e.g. claims or messaging on pack",
  //                 "next_question_id": ""
  //             },
  //             {
  //                 "behavior_prompt": "Off-pack communication",
  //                 "option_metadata": "e.g. messaging through websites or advertisements",
  //                 "next_question_id": ""
  //             },
  //             {
  //                 "behavior_prompt": "In-store activations (price, promotions, displays etc.)",
  //                 "option_metadata": "",
  //                 "next_question_id": ""
  //             },
  //             {
  //                 "behavior_prompt": "Education campaigns",
  //                 "option_metadata": "e.g. Wrigley Oral Healthcare Program that drives education on benefits of sugar-free gum",
  //                 "next_question_id": ""
  //             },
  //             {
  //                 "behavior_prompt": "Brand programs or campaigns",
  //                 "option_metadata": "e.g. Maltesers Maternal Mental Health Campaign",
  //                 "next_question_id": ""
  //             },
  //             {
  //                 "behavior_prompt": "Partnerships",
  //                 "option_metadata": "",
  //                 "next_question_id": ""
  //             }
  //         ],
  //         "avatar_name": "",
  //         "avatar_text": ""
  //     }
  // ],

  poc_product_questions: [
    {
      question_id: 1,
      question: 'Are you designing a Product or Experience?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          innovation: 'Product',
          option_metadata:
            'Any innovation that involves a product and brand-related communications',
          next_question_id: 2,
        },
        {
          innovation: 'Experience',
          option_metadata:
            'Anything that does not involve a product innovation (e.g. a service, or a brand campaign on an existing product etc.)',
          next_question_id: 3,
        },
      ],
      avatar_name: 'avatar_1',
      avatar_text:
        'Hi! I’m Welby the Wallaby. I’ll be popping in once and while to remind you to take breaks and prioritize your wellbeing!',
    },
    {
      question_id: 2,
      question: 'What type of Product are you?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          product: 'Treats, Core',
          option_metadata:
            'An indulgent product that may have limited nutritional value, and is intended to be consumed in smaller portions, infrequently, and in moderation',
          next_question_id: 7,
        },
        {
          product: 'Treats, Reduced Sugar',
          option_metadata: 'Treats with a claimable reduction in sugar',
          next_question_id: 8,
        },
        {
          product: 'Snacks, Base',
          option_metadata:
            'A food containing at least 1/2 serving of a recommended food with a claimable reduction in sugar, that is consumed between mealtimes. In general, a snack will contain 10% of the total calories of the day',
          next_question_id: 9,
        },
        {
          product: 'Snacks, Nutrient Dense',
          option_metadata:
            'Foods that provide a meaningful amount of a recommended food group and do not exceed nutrients to limit thresholds or achieve a rating of at least 3.5 stars on the Australian Health Star nutrient profiling model',
          next_question_id: 10,
        },
        {
          product: 'Gum, Mints, Others',
          option_metadata:
            'Nutritionally insignificant formats like gum, mints, hard candy, etc. ',
          next_question_id: 11,
        },
      ],
      hover: true,
      avatar_name: 'avatar_2',
      avatar_text:
        'Don’t forget to move in your day! Take a quick 60 second stretch break.',
    },
    {
      question_id: 3,
      question: 'Is this Experience part of a Global Brand?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          experience: 'Yes',
          option_metadata: '',
          next_question_id: 4,
        },
        {
          experience: 'No',
          option_metadata: '',
          next_question_id: 5,
        },
      ],
      avatar_name: '',
      avatar_text: '',
    },
    {
      question_id: 4,
      question:
        'Has the brand defined an emotional wellbeing focus area as part of the Nurturing Wellbeing for Generations (NWfG) commitment?',
      question_metadata:
        'Click here for information about the NWfG emotional wellbeing commitments.',
      link: 'https://wellbeingforgenerations.com/',
      select_type: 'Single Select',
      options: [
        {
          emotional_wellbeing: 'Yes',
          option_metadata: '',
          next_question_id: 5,
        },
        {
          emotional_wellbeing: 'No',
          option_metadata: '',
          next_question_id: 5,
        },
        {
          emotional_wellbeing: 'Unsure / Work in Progress',
          option_metadata: '',
          next_question_id: 5,
        },
      ],
      avatar_name: '',
      avatar_text: '',
    },
    {
      question_id: 5,
      question:
        'Which Wellbeing Dimension are you hoping to drive impact in through this Experience?',
      question_metadata: 'Select all that apply.',
      link: '',
      select_type: 'Multi Select',
      options: [
        {
          dimension: 'Emotional Wellbeing',
          option_metadata:
            'Self awareness of feelings to positively shift behaviors that builds connection (to self, to others, to nature) and overall resilience.',
          next_question_id: 6,
        },
        {
          dimension: 'Physical Wellbeing',
          option_metadata:
            'Essential daily routines – breathing, sleeping, eating, moving – to minimize fatigue or physical stress.',
          next_question_id: '',
        },
        {
          dimension: 'Social Wellbeing',
          option_metadata:
            'Intentional action to reducing barriers for our communities for products that are nutritious, affordable & accessible as well as sustainably grown & produced',
          next_question_id: '',
        },
      ],
      avatar_name: 'avatar_3',
      avatar_text:
        "Take a moment to practice a breathing exercise. Breathing is a wonderful way to feel like you're in control!",
    },
    {
      question_id: 6,
      question:
        "Please consider how this experience might impact someone's Emotional Wellbeing?",
      question_metadata:
        'Please select the emotional wellbeing focus area this experience will prioritize to address your target population’s need (e.g. low self esteem, anxiety, loneliness, stress).',
      link: '',
      select_type: 'Multi Select',
      options: [
        {
          focus_area: 'Calm',
          option_metadata:
            'State of peace of mind, restfulness free from agitation:*Calm and connected*High levels of self - esteem;healthy social relationships',
          next_question_id: '',
        },
        {
          focus_area: 'Confidence',
          option_metadata:
            'Openness to new experiences & challenges:*Assertive, high - levels of self - efficacy*Negative events viewed as external, temporary, specific',
          next_question_id: '',
        },
        {
          focus_area: 'Connection',
          option_metadata:
            'Quality social connections:*Sense of securely attached relationships*Increased life satisfaction & happiness(predictor for overall physical health & longevity)',
          next_question_id: '',
        },
        {
          focus_area: 'Control',
          option_metadata:
            'High state of agency to handle & respond to situations:*Positive outlook on future, feeling calm & collected*Energetic, healthy',
          next_question_id: '',
        },
      ],
      hover: true,
      avatar_name: '',
      avatar_text: '',
    },
    {
      question_id: 7,
      question: 'Is this product going to be < = 200 kcal per serving?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          kcal_per_200_serving: 'Yes',
          option_metadata: '',
          next_question_id: 16,
        },
        {
          kcal_per_200_serving: 'No',
          option_metadata: '',
          next_question_id: 12,
        },
      ],
      avatar_name: 'avatar_4',
      avatar_text:
        'The following questions will be about product development. /nSave and quit to share this assessment with your teammates, or have them close by to help you!',
    },
    {
      question_id: 8,
      question: 'Is this product going to be < = 200 kcal per serving?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          kcal_per_200_serving: 'Yes',
          option_metadata: '',
          next_question_id: 13,
        },
        {
          kcal_per_200_serving: 'No',
          option_metadata: '',
          next_question_id: 13,
        },
      ],
      avatar_name: 'avatar_4',
      avatar_text:
        'The following questions will be about product development. /nSave and quit to share this assessment with your teammates, or have them close by to help you!',
    },
    {
      question_id: 9,
      question: 'Is this product going to be < = 200 kcal per serving?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          kcal_per_200_serving: 'Yes',
          option_metadata: '',
          next_question_id: 18,
        },
        {
          kcal_per_200_serving: 'No',
          option_metadata: '',
          next_question_id: 14,
        },
      ],
      avatar_name: 'avatar_4',
      avatar_text:
        'The following questions will be about product development. /nSave and quit to share this assessment with your teammates, or have them close by to help you!',
    },
    {
      question_id: 10,
      question: 'Is this product going to be < = 250 kcal per serving?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          kcal_per_250_serving: 'Yes',
          option_metadata: '',
          next_question_id: 15,
        },
        {
          kcal_per_250_serving: 'No',
          option_metadata: '',
          next_question_id: 15,
        },
      ],
      avatar_name: 'avatar_4',
      avatar_text:
        'The following questions will be about product development. /nSave and quit to share this assessment with your teammates, or have them close by to help you!',
    },
    {
      question_id: 11,
      question:
        'Do you meet the nutritional guidelines per the Product Design Toolkit?',
      question_metadata:
        'Click here/ for information about the Product Design Toolkit.',
      link: 'https://wellbeingforgenerations.com/',
      select_type: 'Single Select',
      options: [
        {
          nutritional_guidelines: 'Yes',
          option_metadata: '',
          next_question_id: 22,
        },
        {
          nutritional_guidelines: 'No',
          option_metadata: '',
          next_question_id: 22,
        },
        {
          nutritional_guidelines: 'Unsure / Work in Progress',
          option_metadata: '',
          next_question_id: 22,
        },
      ],
      avatar_name: 'avatar_6',
      avatar_text:
        'Take a minute and send someone you know a quick message! Connections help build resislience!',
    },
    {
      question_id: 12,
      question: 'Is this product going to be <= 250 kcal per serving?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          kcal_per_250_serving: 'Yes',
          option_metadata: '',
          next_question_id: 16,
        },
        {
          kcal_per_250_serving: 'No',
          option_metadata: '',
          next_question_id: 16,
        },
      ],
      avatar_name: '',
      avatar_text: '',
    },
    {
      question_id: 13,
      question: 'Is this product going to be <= 25 g sugar per serving?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          kcal_per_25_serving: 'Yes',
          option_metadata: '',
          next_question_id: 17,
        },
        {
          kcal_per_25_serving: 'No',
          option_metadata: '',
          next_question_id: 17,
        },
      ],
      avatar_name: '',
      avatar_text: '',
    },
    {
      question_id: 14,
      question: 'Is this product going to be < = 250 kcal per serving?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          kcal_per_250_serving: 'Yes',
          option_metadata: '',
          next_question_id: 18,
        },
        {
          kcal_per_250_serving: 'No',
          option_metadata: '',
          next_question_id: 18,
        },
      ],
      avatar_name: '',
      avatar_text: '',
    },
    {
      question_id: 15,
      question: 'Will you meet the Mars definition of a nutrient dense snack?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          nutrient_dense: 'Yes',
          option_metadata: '',
          next_question_id: '',
        },
        {
          nutrient_dense: 'No',
          option_metadata: '',
          next_question_id: 19,
        },
      ],
      avatar_name: '',
      avatar_text: '',
    },
    {
      question_id: 16,
      question: 'Is this product going to be <= 25 g sugar per serving?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          sugar_per_25_serving: 'Yes',
          option_metadata: '',
          next_question_id: '',
        },
        {
          sugar_per_25_serving: 'No',
          option_metadata: '',
          next_question_id: '',
        },
      ],
      avatar_name: 'avatar_5',
      avatar_text:
        "Take a moment to practice a breathing exercise. Breathing is a wonderful way to feel like you're in control!",
    },
    {
      question_id: 17,
      question:
        'Will you have a claimable reduction in sugar (from a referenceable product)?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          sugar_reduction: 'Yes',
          option_metadata: '',
          next_question_id: '',
        },
        {
          sugar_reduction: 'No',
          option_metadata: '',
          next_question_id: '',
        },
      ],
      avatar_name: 'avatar_5',
      avatar_text:
        "Take a moment to practice a breathing exercise. Breathing is a wonderful way to feel like you're in control!",
    },
    {
      question_id: 18,
      question: 'Is this product going to be <= 25 g sugar per serving?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          sugar_per_25_serving: 'Yes',
          option_metadata: '',
          next_question_id: 20,
        },
        {
          sugar_per_25_serving: 'No',
          option_metadata: '',
          next_question_id: 20,
        },
      ],
      avatar_name: '',
      avatar_text: '',
    },
    {
      question_id: 19,
      question: 'Will you meet one of the following?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          nutrient: 'Having a Health Star Rating of > 3.5',
          option_metadata: '',
          next_question_id: '',
        },
        {
          nutrient:
            'Having a Health Star Rating of > 3.5 AND containing meaningful amount of recommended food',
          option_metadata: '',
          next_question_id: '',
        },
        {
          nutrient: 'None of the above',
          option_metadata: '',
          next_question_id: '',
        },
      ],
      avatar_name: 'avatar_5',
      avatar_text:
        "Take a moment to practice a breathing exercise. Breathing is a wonderful way to feel like you're in control!",
    },
    {
      question_id: 20,
      question:
        'Will you have a claimable reduction in sugar (from a referenceable product)?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          claimable_reduction: 'Yes',
          option_metadata: '',
          next_question_id: 21,
        },
        {
          claimable_reduction: 'No',
          option_metadata: '',
          next_question_id: 21,
        },
      ],
      avatar_name: '',
      avatar_text: '',
    },
    {
      question_id: 21,
      question:
        'Are you committed to having a minimum 1/2 serving of a recommended food per serving?',
      question_metadata: 'Click here/ to learn more about recommended foods.',
      link: 'https://wellbeingforgenerations.com/',
      select_type: 'Single Select',
      options: [
        {
          recommended_food: 'Yes',
          option_metadata: '',
          next_question_id: '',
        },
        {
          recommended_food: 'No',
          option_metadata: '',
          next_question_id: '',
        },
      ],
      avatar_name: 'avatar_5',
      avatar_text:
        "Take a moment to practice a breathing exercise. Breathing is a wonderful way to feel like you're in control!",
    },
    {
      question_id: 22,
      question: 'Will you have a portion icon on pack?',
      question_metadata: 'Click here/ for information about portion icons.',
      link: 'https://wellbeingforgenerations.com/',
      select_type: 'Single Select',
      options: [
        {
          icon_pack: 'Yes',
          option_metadata: '',
          next_question_id: 23,
        },
        {
          icon_pack: 'No',
          option_metadata: '',
          next_question_id: 23,
        },
        {
          icon_pack: 'Unsure / Work in Progress',
          option_metadata: '',
          next_question_id: 23,
        },
      ],
      avatar_name: 'avatar_7',
      avatar_text:
        'The next set of questions are about marketing. /nSave and quit to share this assessment with your teammates, or have them close by to help you!',
    },
    {
      question_id: 23,
      question: 'Will you have a GDA label on pack?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          gda_label: 'Yes',
          option_metadata: '',
          next_question_id: 24,
        },
        {
          gda_label: 'No',
          option_metadata: '',
          next_question_id: 24,
        },
        {
          gda_label: 'Not Applicable',
          option_metadata: '',
          next_question_id: 24,
        },
      ],
      avatar_name: '',
      avatar_text: '',
    },
    {
      question_id: 24,
      question: 'Is this proof of concept part of a Global Brand?',
      question_metadata: '',
      link: '',
      select_type: 'Single Select',
      options: [
        {
          proof_of_concept: 'Yes',
          option_metadata: '',
          next_question_id: 25,
        },
        {
          proof_of_concept: 'No',
          option_metadata: '',
          next_question_id: 25,
        },
      ],
      avatar_name: 'avatar_8',
      avatar_text:
        'Take a minute and send someone you know a quick message! Connections help build resislience!',
    },
    {
      question_id: 25,
      question:
        'Has the brand defined an emotional wellbeing focus area as part of the Nurturing Wellbeing for Generations (NWfG) commitment?',
      question_metadata:
        'Click here/ for information about the NWfG emotional wellbeing commitments.',
      link: 'https://wellbeingforgenerations.com/',
      select_type: 'Single Select',
      options: [
        {
          emotional_wellbeing: 'Yes',
          option_metadata: '',
          next_question_id: 26,
        },
        {
          emotional_wellbeing: 'No',
          option_metadata: '',
          next_question_id: 26,
        },
        {
          emotional_wellbeing: 'Unsure / Work in Progress',
          option_metadata: '',
          next_question_id: 26,
        },
      ],
      avatar_name: '',
      avatar_text: '',
    },
    {
      question_id: 26,
      question:
        'Which Wellbeing Dimension are you hoping to drive impact in through this Experience?',
      question_metadata: 'Select all that apply.',
      link: '',
      select_type: 'Multi Select',
      options: [
        {
          dimension: 'Emotional Wellbeing',
          option_metadata:
            'Self awareness of feelings to positively shift behaviors that builds connection (to self, to others, to nature) and overall resilience.',
          next_question_id: 27,
        },
        {
          dimension: 'Physical Wellbeing',
          option_metadata:
            'Essential daily routines – breathing, sleeping, eating, moving – to minimize fatigue or physical stress.',
          next_question_id: '',
        },
        {
          dimension: 'Social Wellbeing',
          option_metadata:
            'Intentional action to reducing barriers for our communities for products that are nutritious, affordable & accessible as well as sustainably grown & produced',
          next_question_id: '',
        },
      ],
      avatar_name: '',
      avatar_text: '',
    },
    {
      question_id: 27,
      question:
        "Please consider how this experience might impact someone's Emotional Wellbeing?",
      question_metadata:
        'Please select the emotional wellbeing focus area this experience will prioritize to address your target population’s need (e.g. low self esteem, anxiety, loneliness, stress).',
      link: '',
      select_type: 'Multi Select',
      options: [
        {
          focus_area: 'Calm',
          option_metadata:
            'State of peace of mind, restfulness free from agitation:*Calm and connected*High levels of self - esteem;healthy social relationships',
          next_question_id: '',
        },
        {
          focus_area: 'Confidence',
          option_metadata:
            'Openness to new experiences & challenges:*Assertive, high - levels of self - efficacy*Negative events viewed as external, temporary, specific',
          next_question_id: '',
        },
        {
          focus_area: 'Connection',
          option_metadata:
            'Quality social connections:*Sense of securely attached relationships*Increased life satisfaction & happiness(predictor for overall physical health & longevity)',
          next_question_id: '',
        },
        {
          focus_area: 'Control',
          option_metadata:
            'High state of agency to handle & respond to situations:*Positive outlook on future, feeling calm & collected*Energetic, healthy',
          next_question_id: '',
        },
      ],
      hover: true,
      avatar_name: 'avatar_9',
      avatar_text:
        'Write down 1-3 things you are grateful for today. Gratitude can help you feel calm!',
    },
    {
      question_id: 28,
      question:
        'Across which consumer touchpoints will the behavior prompts show up?',
      question_metadata: 'Select all that apply.',
      link: '',
      select_type: 'Multi Select',
      options: [
        {
          behavior_prompt: 'Product',
          option_metadata: '',
          next_question_id: '',
        },
        {
          behavior_prompt: 'Pack Format or Structural Design',
          option_metadata: 'e.g. portionable pack',
          next_question_id: '',
        },
        {
          behavior_prompt: 'On-pack communication',
          option_metadata: 'e.g. claims or messaging on pack',
          next_question_id: '',
        },
        {
          behavior_prompt: 'Off-pack communication',
          option_metadata: 'e.g. messaging through websites or advertisements',
          next_question_id: '',
        },
        {
          behavior_prompt:
            'In-store activations (price, promotions, displays etc.)',
          option_metadata: '',
          next_question_id: '',
        },
        {
          behavior_prompt: 'Education campaigns',
          option_metadata:
            'e.g. Wrigley Oral Healthcare Program that drives education on benefits of sugar-free gum',
          next_question_id: '',
        },
        {
          behavior_prompt: 'Brand programs or campaigns',
          option_metadata: 'e.g. Maltesers Maternal Mental Health Campaign',
          next_question_id: '',
        },
        {
          behavior_prompt: 'Partnerships',
          option_metadata: '',
          next_question_id: '',
        },
      ],
      avatar_name: '',
      avatar_text: '',
    },
  ],
}
export const initialState = {
  steps: [
    { id: '1' },
    { id: '2' },
    { id: '3' },
    { id: '4' },
    { id: '5' },
    { id: '6' },
    { id: '7' },
    { id: '8' },
    { id: '9' },
    { id: '10' },
    { id: '11' },
    { id: '12' },
    { id: '13' },
    { id: '14' },
    { id: '15' },
  ],
  formData: {
    project_name: '',
    project_id: '',
  },
  questions: [],
  propositionId: '',
  propositionName: '',
  pocId: '',
  pocName: '',
  answers: [],
  snackerBar: false,
  physicalWellbeing: {},
  socialWellbeing: {},
  emotionalWellbeing: {},
  physicalWellbeingResult: {},
  emotionalWellbeingResult: {},
  socialWellbeingResult: {},
  tabs: [],
  questionId: '',
  poc_details: {},
  general_answers: [],
  producttype: '',
}

export const pocProductSlice = createSlice({
  name: 'poc',
  initialState,
  reducers: {
    setStep: (state, action) => {
      console.log('slice: ', action.payload)
      state.steps = action.payload
    },
    setForm: (state, action) => {
      state.formData = { ...state.formData, project_name: action.payload }
    },
    setProjectId: (state, action) => {
      state.formData = { ...state.formData, project_id: action.payload }
    },
    setPropositionId: (state, action) => {
      state.propositionId = action.payload
    },
    setPocId: (state, action) => {
      state.pocId = action.payload
    },
    addProductQuestions: (state, action) => {
      state.questions = [...action?.payload] || []
    },
    resumeAnswers: (state, action) => {
      state.answers = [...action.payload]
    },
    // setAnswers: (state, action) => {
    //   let isPresent = false

    //   const updatedAnswers = [...state.answers]
    //   if (Object.keys(action.payload).length > 0) {
    //     if (updatedAnswers.length > 0) {
    //       updatedAnswers.forEach((item) => {
    //         if (item.question_id === action.payload.question_id) {
    //           isPresent = true
    //         }
    //       })
    //       if (isPresent) {
    //         const filtered = updatedAnswers.filter(
    //           (item1) => item1.question_id !== action.payload.question_id
    //         )
    //         state.answers = [...filtered, { ...action.payload }]
    //       } else {
    //         state.answers = [...updatedAnswers, { ...action.payload }]
    //       }
    //     } else {
    //       state.answers = [{ ...action.payload }]
    //     }
    //   }
    // },
    emptyStore: (state) => {
      state.formData = {
        project_name: '',
        project_id: '',
      }
      state.questions = []
      state.propositionId = ''
      state.pocId = ''
      state.answers = []
    },
    emptyAnswers: (state) => {
      state.answers = []
    },
    updateSnackerBar: (state, action) => {
      state.snackerBar = action.payload
    },
    getWellbeingAfterSubmitAnswer: (state, action) => {
      const { Physical, Social } = action.payload
      state.physicalWellbeing = { ...Physical }
      state.socialWellbeing = { ...Social }
    },
    getEmotionalWellbeingAfterSubmit: (state, action) => {
      const { Emotional } = action.payload
      state.emotionalWellbeing = { ...Emotional }
    },
    setQuestionId: (state, action) => {
      state.questionId = action.payload
    },
    setPropositionName: (state, action) => {
      state.propositionName = action.payload
    },
    setPocName: (state, action) => {
      state.pocName = action.payload
    },
    setPocDetails: (state, action) => {
      state.poc_details = action.payload
      console.log(action.payload)
    },
    addProductType: (state, action) => {
      state.producttype = action.payload
    },
  },
})

export const {
  setStep,
  setForm,
  addProductQuestions,
  setProjectId,
  setPropositionId,
  setPocId,
  // setAnswers,
  emptyStore,
  emptyAnswers,
  updateSnackerBar,
  getWellbeingAfterSubmitAnswer,
  getEmotionalWellbeingAfterSubmit,
  setQuestionId,
  resumeAnswers,
  setPropositionName,
  setPocName,
  setPocDetails,
  addProductType,
} = pocProductSlice.actions

export default pocProductSlice.reducer
