import { sendSSODataToDjango } from '../Services/api'
import { matomoInstance } from '../../App'

export const UserDataSave = () => {
  const keys = Object.keys(sessionStorage)
  const key = keys.filter((s) => s.includes('accesstoken'))
  const tokenkey = key[0]
  const accessTokenKey = 'msal.token.keys.45922ff5-9538-4f12-b15b-cbdc35ca4d20'
  const idToken = JSON.parse(sessionStorage.getItem(tokenkey))?.secret
  const accesstoken = JSON.parse(sessionStorage.getItem(accessTokenKey))
    ?.accessToken[0]

  if (idToken) {
    const tokenParts = idToken.split('.')
    if (tokenParts.length === 3) {
      // Decode the base64url encoded payload
      const payload = atob(tokenParts[1])
      const userData = JSON.parse(payload)

      const username = userData.name
      const email = userData.unique_name
      const oid = userData.oid

      const ssoData = {
        user_data: {
          username: username,
          email: email,
          oid: oid,
        },
        access_token: accesstoken,
      }
      localStorage.setItem('oid', userData.oid)
      localStorage.setItem('expDate', userData.exp)
      localStorage.setItem('idToken', idToken)
      localStorage.setItem('username', username)
      localStorage.setItem('email', email)
      matomoInstance.pushInstruction('setUserId', email)
      sendSSODataToDjango(ssoData)
      return username
    }
  }
  return localStorage.getItem('username')
}
