import { configureStore } from '@reduxjs/toolkit'
import projectSlice from './slices/projectsSlice'
import propositionSlice from './slices/propositionSlice'
import PSEwellbeingSlice from './slices/PSEwellbeingSlice'
import pocSlice from './slices/PocSlice'
import pocProductSlice from './slices/PocProductSlice'
// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
  // Automatically calls `combineReducers`
  reducer: {
    projects: projectSlice,
    preposition: propositionSlice,
    poc: pocSlice,
    pocProduct: pocProductSlice,
    PSEwellbeing: PSEwellbeingSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
})
export default store
