import React from 'react'
import Navbar from './Components/Navbar'
import { ContentCard, MainCard, MainContentCard } from './Components/Cards'
import MissionSection from './Sections/Mission'
import ScoreSection from './Sections/Scores'
import FooterComponent from './Components/Footer'
import { emptyStore } from '../../Redux/slices/propositionSlice'
import { setIsProposition, setProjectId } from '../../Redux/slices/projectsSlice'
import { setForm, setPropositionName, setTypeofennovation } from '../../Redux/slices/PocSlice'
import { ResetWellbeing } from '../../Redux/slices/PSEwellbeingSlice'
import { useDispatch, batch } from 'react-redux'

const LandingPage = () => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    localStorage.removeItem('status')
    localStorage.removeItem('nextId')
    localStorage.removeItem('piller')
    localStorage.removeItem('projectId')
    localStorage.removeItem('pocbeginning')
    localStorage.removeItem('pocType')
    localStorage.removeItem('pocbeginning')
    localStorage.removeItem('poccreated')
    batch(() => {
      dispatch(emptyStore())
      dispatch(setIsProposition(false))
      dispatch(setForm())
      dispatch(setProjectId())
      dispatch(setPropositionName(''))
      dispatch(ResetWellbeing())
      dispatch(setTypeofennovation(''))
    })
  }, [])
  return (
    <div style={{ overflowX: 'hidden', position: 'relative' }}>
      <div
        style={{
          height: '699px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '16px',
          flexShrink: '0',
          background: '#f2fbff',
        }}
      >
        <Navbar />
        <MainCard />
      </div>
      <div
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <MainContentCard />
        <MissionSection />
        <ContentCard />
        <ScoreSection />
      </div>
      <div style={{ width: '100%', overflow: 'visible' }}>
        <FooterComponent />
      </div>
    </div>
  )
}

export default LandingPage
