function CircleImageOverlayComponent({ imgSrc, circleColor }) {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '372.98px',
          height: '372.98px',
          backgroundColor: circleColor,
          borderRadius: '50%',
        }}
      ></div>
      <img
        src={imgSrc}
        alt='Overlay Image'
        style={{ objectFit: 'cover', position: 'absolute', zIndex: 1 }}
      />
    </div>
  )
}

export default CircleImageOverlayComponent
