import { createSlice } from '@reduxjs/toolkit'
const fetchQuestionsPayload = {
  proposition_questions: [
    {
      question_id: 1,
      question: 'What type of innovation are you designing for?',
      question_metadata: '',
      select_type: 'Single Select',
      options: [
        {
          innovation: 'Product',
          option_metadata: '',
          next_question_id: 2,
        },
        {
          innovation: 'Experience',
          option_metadata: '',
          next_question_id: 3,
        },
      ],
    },
    {
      question_id: 2,
      question: 'What type of Product are you?',
      question_metadata: '',
      select_type: 'Single Select',
      options: [
        {
          product: 'Treat',
          option_metadata:
            'An indulgent product  that may have limited nutritional value, and is intended to be consumed in smaller portions, infrequently, and in moderation',
          next_question_id: 3,
        },
        {
          product: 'Improved Nutrition Treat',
          option_metadata:
            'Improved versions of a treat that make people feel less guilty about their choices. They offer indulgence with claimable reduction in sugar.',
          next_question_id: 3,
        },
        {
          product: 'Improved Nutrition/Nutrition Snack',
          option_metadata:
            'A beverage or a food that is consumed between mealtimes containing some nutritional benefits and recommended foods. In general, a snack will contain 10% of the total calories of the day. It will offer at least 30% reduction/ claimable reduction in sugar + a recommended food.',
          next_question_id: 3,
        },
        {
          product: 'Nutrient Dense Snack',
          option_metadata:
            'Foods that achieve a rating of at least 3.5 stars on the Australian Health Star nutrient profiling model or provide positive nutrition through a meaningful amount of a recommended food group and do not exceed nutrients to limit thresholds that are consistent with the intended eating occasion.',
          next_question_id: 3,
        },
        {
          product: 'Gum/Mints',
          option_metadata: 'Nutritionally insignificant gum & mint products',
          next_question_id: 3,
        },
      ],
    },
    {
      question_id: 3,
      question: 'What Need State are you solving for?',
      question_metadata: 'Select all that apply',
      select_type: 'Multi Select',
      options: [
        {
          experience: 'Healthier Intent',
          option_metadata:
            'More nutritious, guilt-free, lower sugar, and lower in calories',
          next_question_id: 4,
        },
        {
          experience: 'Fuel',
          option_metadata:
            'Energizing, satisfies hunger, higher in protein, gives you a boost',
          next_question_id: 4,
        },
        {
          experience: 'Relax',
          option_metadata:
            'Reduce stress, provide a mental break, improve mood',
          next_question_id: 4,
        },
        {
          experience: 'Connection',
          option_metadata:
            'Sharing with others, rewarding, great for celebrating',
          next_question_id: 4,
        },
        {
          experience: 'Enjoy',
          option_metadata:
            'Fun to eat, great taste, provide feelings of joy and delight',
          next_question_id: 4,
        },
      ],
    },
    {
      question_id: 4,
      question: 'What Occasion are you solving for?',
      question_metadata: 'Select all that apply',
      select_type: 'Multi Select',
      options: [
        {
          occasion: 'Morning',
          option_metadata: '',
          next_question_id: '',
        },
        {
          occasion: 'Daytime',
          option_metadata: '',
          next_question_id: '',
        },
        {
          occasion: 'Evening',
          option_metadata: '',
          next_question_id: '',
        },
        {
          occasion: 'At Home',
          option_metadata: '',
          next_question_id: '',
        },
        {
          occasion: 'Out of Home',
          option_metadata: '',
          next_question_id: '',
        },
      ],
    },
    {
      question_id: 7,
      question:
        'First, select the focus areas you’d like to incorporate into your design process.',
      question_metadata: '',
      select_type: 'Multi Select',
      options: [
        {
          focus_area: 'Calm',
          option_metadata: '',
          next_question_id: '',
        },
        {
          focus_area: 'Confidence',
          option_metadata: '',
          next_question_id: '',
        },
        {
          focus_area: 'Connection',
          option_metadata: '',
          next_question_id: '',
        },
        {
          focus_area: 'Control',
          option_metadata: '',
          next_question_id: '',
        },
      ],
    },
  ],
}
export const initialState = {
  steps: [
    { id: '1' },
    { id: '2' },
    { id: '3' },
    { id: '4' },
    { id: '5' },
    { id: '6' },
    { id: '7' },
    { id: '8' },
    { id: '9' },
    { id: '10' },
    { id: '11' },
    { id: '12' },
    { id: '13' },
    { id: '14' },
    { id: '15' },
  ],
  formData: {
    project_name: '',
    project_id: '',
  },
  questions: [],
  propositionId: '',
  propositionName: '',
  answers: [],
  snackerBar: false,
  physicalWellbeing: {},
  socialWellbeing: {},
  emotionalWellbeing: {},
  physicalWellbeingResult: {},
  emotionalWellbeingResult: {},
  socialWellbeingResult: {},
  tabs: [],
  questionId: '',
  proposition_details: {},
  requirements: {},
  general_answers: [],
  hyperlinks: {},
  project_info: {},
}

export const propositionSlice = createSlice({
  name: 'proposition',
  initialState,
  reducers: {
    setStep: (state, action) => {
      console.log('slice: ', action.payload)
      state.steps = action.payload
    },
    setForm: (state, action) => {
      state.formData = { ...state.formData, project_name: action.payload }
    },
    setProjectId: (state, action) => {
      state.formData = { ...state.formData, project_id: action.payload }
    },
    setPropositionId: (state, action) => {
      state.propositionId = action.payload
    },
    getQuestions: (state, action) => {
      state.questions = [...action?.payload] || []
    },
    resumeAnswers: (state, action) => {
      state.answers = [...action.payload]
    },
    setAnswers: (state, action) => {
      let isPresent = false
      const updatedAnswers = [...state.answers]
      if (Object.keys(action.payload).length > 0) {
        if (updatedAnswers.length > 0) {
          updatedAnswers.forEach((item) => {
            if (item.question_id === action.payload.question_id) {
              isPresent = true
            }
          })
          if (isPresent) {
            const filtered = updatedAnswers.filter(
              (item1) => item1.question_id !== action.payload.question_id
            )
            state.answers = [...filtered, { ...action.payload }]
          } else {
            state.answers = [...updatedAnswers, { ...action.payload }]
          }
        } else {
          state.answers = [{ ...action.payload }]
        }
      }
    },
    emptyStore: (state) => {
      state.formData = {
        project_name: '',
        project_id: '',
      }
      state.questions = []
      state.propositionId = ''
      state.answers = []
    },
    emptyAnswers: (state) => {
      state.answers = []
    },
    updateSnackerBar: (state, action) => {
      state.snackerBar = action.payload
    },
    getWellbeingAfterSubmitAnswer: (state, action) => {
      const { Physical, Social } = action.payload
      state.physicalWellbeing = { ...Physical }
      state.socialWellbeing = { ...Social }
    },
    getEmotionalWellbeingAfterSubmit: (state, action) => {
      const { Emotional } = action.payload
      state.emotionalWellbeing = { ...Emotional }
    },
    setResults: (state, action) => {
      const {
        Physical,
        Social,
        Emotional,
        proposition_details,
        general_answers,
        requirements,
        hyperlinks,
        project_info,
      } = action.payload
      state.physicalWellbeingResult = Physical
      state.emotionalWellbeingResult = Emotional
      state.socialWellbeingResult = Social
      const physicalKeys = Object.keys(Physical).filter(
        (item) => Physical[item]?.length > 0
      )
      const socialKeys = Object.keys(Social).filter(
        (item) => Social[item]?.length > 0
      )
      const emotionalKeys = Object.keys(Emotional).filter(
        (item) => Emotional[item]?.length > 0
      )
      let wellbeingTabs = []
      if (physicalKeys.length > 0) {
        wellbeingTabs.push('Physical Wellbeing')
      }
      if (socialKeys.length > 0) {
        wellbeingTabs.push('Social Wellbeing')
      }
      if (emotionalKeys.length > 0) {
        wellbeingTabs.push('Emotional Wellbeing')
      }
      wellbeingTabs.push('Other Guidelines')
      state.tabs = wellbeingTabs
      state.proposition_details = proposition_details
      state.general_answers = general_answers
      state.requirements = requirements
      state.hyperlinks = hyperlinks
      state.project_info = project_info
    },
    setQuestionId: (state, action) => {
      state.questionId = action.payload
    },
    setPropositionName: (state, action) => {
      state.propositionName = action.payload
    },
  },
})

export const {
  setStep,
  setForm,
  getQuestions,
  setProjectId,
  setPropositionId,
  setAnswers,
  emptyStore,
  emptyAnswers,
  updateSnackerBar,
  getWellbeingAfterSubmitAnswer,
  getEmotionalWellbeingAfterSubmit,
  setResults,
  setQuestionId,
  resumeAnswers,
  setPropositionName,
} = propositionSlice.actions

export default propositionSlice.reducer
