// msalConfig.js
const client_id = process.env.REACT_APP_CLIENT_ID
export const msalConfig = {
  auth: {
    clientId: client_id, // Replace with your Azure AD Application client ID
    authority:
      'https://login.microsoftonline.com/2fc13e34-f03f-498b-982a-7cb446e25bc6', // Replace with your Azure AD tenant ID or common endpoint
    redirectUri: `${window.location.origin}`,
    postLogoutRedirectUri: `${window.location.origin}`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // You can choose 'localStorage' or 'sessionStorage'
    storeAuthStateInCookie: false,
  },
}
