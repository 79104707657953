import { createSlice } from '@reduxjs/toolkit'
const fetchPocQuestionsPayload = {
  poc_questions: [
    {
      question_id: 1,
      question: 'Are you designing a Product or Experience?',
      question_metadata: '',
      select_type: 'Single Select',
      options: [
        {
          innovation: 'Product',
          option_metadata:
            'Any innovation that involves a product and brand-related communications',
          next_question_id: 2,
        },
        {
          innovation: 'Experience',
          option_metadata:
            'Anything that does not involve a product innovation (e.g. a service, or a brand campaign on an existing product etc.)',
          next_question_id: 3,
        },
      ],
    },
    {
      question_id: 2,
      question: 'What type of Product are you?',
      question_metadata: '',
      select_type: 'Single Select',
      options: [
        {
          product: 'Treats, Core',
          option_metadata:
            'An indulgent product that may have limited nutritional value, and is intended to be consumed in smaller portions, infrequently, and in moderation',
          next_question_id: 7,
        },
        {
          product: 'Treats, Reduced Sugar',
          option_metadata: 'Treats with a claimable reduction in sugar',
          next_question_id: 7,
        },
        {
          product: 'Snacks, Base',
          option_metadata:
            'A food containing at least 1/2 serving of a recommended food with a claimable reduction in sugar, that is consumed between mealtimes. In general, a snack will contain 10% of the total calories of the day',
          next_question_id: 7,
        },
        {
          product: 'Snacks, Nutrient Dense',
          option_metadata:
            'Foods that provide a meaningful amount of a recommended food group and do not exceed nutrients to limit thresholds or achieve a rating of at least 3.5 stars on the Australian Health Star nutrient profiling model',
          next_question_id: 7,
        },
        {
          product: 'Gum, Mints, Others',
          option_metadata:
            'Nutritionally insignificant formats like gum, mints, hard candy, etc. ',
          next_question_id: 7,
        },
      ],
    },
    {
      question_id: 3,
      question: 'Is this Experience part of a Global Brand?',
      question_metadata: '',
      select_type: 'Single Select',
      options: [
        {
          experience: 'Yes',
          option_metadata: '',
          next_question_id: 4,
        },
        {
          experience: 'No',
          option_metadata: '',
          next_question_id: 6,
        },
      ],
    },
    {
      question_id: 4,
      question:
        'Has the brand defined an emotional wellbeing focus area as part of the Nurturing Wellbeing for Generations (NWfG) commitment?',
      question_metadata:
        'Click here for information about the NWfG emotional wellbeing commitments.',
      select_type: 'Single Select',
      options: [
        {
          emotional_wellbeing: 'Yes',
          option_metadata: '',
          next_question_id: 5,
        },
        {
          emotional_wellbeing: 'No',
          option_metadata: '',
          next_question_id: 5,
        },
        {
          emotional_wellbeing: 'Unsure / Work in Progress',
          option_metadata: '',
          next_question_id: 5,
        },
      ],
    },
    {
      question_id: 5,
      question:
        'Which Wellbeing Dimension are you hoping to drive impact in through this Experience?',
      question_metadata: 'Select all that apply.',
      select_type: 'Multi Select',
      options: [
        {
          dimension: 'Emotional Wellbeing',
          option_metadata:
            'Self awareness of feelings to positively shift behaviors that builds connection (to self, to others, to nature) and overall resilience.',
          next_question_id: 6,
        },
        {
          dimension: 'Physical Wellbeing',
          option_metadata:
            'Essential daily routines – breathing, sleeping, eating, moving – to minimize fatigue or physical stress.',
          next_question_id: '',
        },
        {
          dimension: 'Social Wellbeing',
          option_metadata:
            'Intentional action to reducing barriers for our communities for products that are nutritious, affordable & accessible as well as sustainably grown & produced',
          next_question_id: '',
        },
      ],
    },
    {
      question_id: 6,
      question:
        "Please consider how this experience might impact someone's Emotional Wellbeing?",
      question_metadata:
        'Please select the emotional wellbeing focus area this experience will prioritize to address your target population’s need (e.g. low self esteem, anxiety, loneliness, stress).',
      select_type: 'Multi Select',
      options: [
        {
          focus_area: 'Calm',
          option_metadata:
            'State of peace of mind, restfulness free from agitation:*Calm and connected*High levels of self - esteem;healthy social relationships',
          next_question_id: '',
        },
        {
          focus_area: 'Confidence',
          option_metadata:
            'Openness to new experiences & challenges:*Assertive, high - levels of self - efficacy*Negative events viewed as external, temporary, specific',
          next_question_id: '',
        },
        {
          focus_area: 'Connection',
          option_metadata:
            'Quality social connections:*Sense of securely attached relationships*Increased life satisfaction & happiness(predictor for overall physical health & longevity)',
          next_question_id: '',
        },
        {
          focus_area: 'Control',
          option_metadata:
            'High state of agency to handle & respond to situations:*Positive outlook on future, feeling calm & collected*Energetic, healthy',
          next_question_id: '',
        },
      ],
    },
    {
      question_id: 7,
      question: 'Select a set of questions to answer.',
      question_metadata:
        'This assessment has two parts – one for product development and another for marketing! Have your teammates handy, or click “Invite a Teammate” to share this assessment.',
      select_type: 'Single Select',
      options: [
        {
          question_type: 'Product Development Questions',
          option_metadata: 'Questions related to nutrition and product design',
          next_question_id: '',
        },
        {
          question_type: 'Marketing Questions',
          option_metadata:
            'Questions related to communications, brand, and packaging',
          next_question_id: '',
        },
      ],
    },
  ],
}
export const initialState = {
  steps: [
    { id: '1' },
    { id: '1.1' },
    { id: '2' },
    { id: '3' },
    { id: '4' },
    { id: '5' },
    { id: '6' },
    { id: '7' },
    { id: '8' },
    { id: '9' },
    { id: '10' },
    { id: '11' },
    { id: '12' },
    { id: '13' },
    { id: '14' },
    { id: '15' },
    { id: '16' },
    { id: '17' },
    { id: '18' },
    { id: '19' },
    { id: '20' },
    { id: '21' },
    { id: '22' },
    { id: '23' },
    { id: '24' },
    { id: '25' },
  ],
  formData: {
    project_name: '',
    project_id: '',
  },
  questions: [],
  propositionId: '',
  propositionName: '',
  pocId: '',
  pocName: '',
  answers: [],
  snackerBarPOC: false,
  physicalWellbeing: {},
  socialWellbeing: {},
  emotionalWellbeing: {},
  physicalWellbeingResult: {},
  emotionalWellbeingResult: {},
  socialWellbeingResult: {},
  tabs: [],
  questionId: '',
  poc_details: {},
  general_answers: [],
  typeofennovation: '',
  score_details: {},
  avatar_details: {},
  commitment_details: {},
  hyperlinks: {},
  project_info: {},
}

export const pocSlice = createSlice({
  name: 'poc',
  initialState,
  reducers: {
    setStep: (state, action) => {
      console.log('slice: ', action.payload)
      state.steps = action.payload
    },
    setForm: (state, action) => {
      state.formData = { ...state.formData, project_name: action.payload }
    },
    setProjectId: (state, action) => {
      state.formData = { ...state.formData, project_id: action.payload }
    },
    setPropositionId: (state, action) => {
      state.propositionId = action.payload
    },
    setPocId: (state, action) => {
      state.pocId = action.payload
    },
    addQuestions: (state, action) => {
      state.questions = [...action?.payload] || []
    },
    resumeAnswers: (state, action) => {
      state.answers = [...action.payload]
    },
    setAnswers: (state, action) => {
      let isPresent = false

      const updatedAnswers = [...state.answers]
      if (Object.keys(action.payload).length > 0) {
        if (updatedAnswers.length > 0) {
          updatedAnswers.forEach((item) => {
            if (item.question_id === action.payload.question_id) {
              isPresent = true
            }
          })
          if (isPresent) {
            const filtered = updatedAnswers.filter(
              (item1) => item1.question_id !== action.payload.question_id
            )
            state.answers = [...filtered, { ...action.payload }]
          } else {
            state.answers = [...updatedAnswers, { ...action.payload }]
          }
        } else {
          state.answers = [{ ...action.payload }]
        }
      }
    },
    emptyStore: (state) => {
      state.formData = {
        project_name: '',
        project_id: '',
      }
      state.questions = []
      state.propositionId = ''
      state.pocId = ''
      state.answers = []
    },
    emptyAnswers: (state) => {
      state.answers = []
    },
    updateSnackerBar: (state, action) => {
      state.snackerBarPOC = action.payload
    },
    getWellbeingAfterSubmitAnswer: (state, action) => {
      const { Physical, Social } = action.payload
      state.physicalWellbeing = { ...Physical }
      state.socialWellbeing = { ...Social }
    },
    getEmotionalWellbeingAfterSubmit: (state, action) => {
      const { Emotional } = action.payload
      state.emotionalWellbeing = { ...Emotional }
    },
    setPocResults: (state, action) => {
      const {
        Physical,
        Social,
        Emotional,
        poc_details,
        general_answers,
        score_details,
        avatar_details,
        commitment_details,
        hyperlinks,
        project_info,
      } = action.payload
      state.physicalWellbeingResult = Physical
      state.emotionalWellbeingResult = Emotional
      state.socialWellbeingResult = Social
      const physicalKeys =
        (Physical &&
          Object.keys(Physical)?.filter(
            (item) => Physical[item]?.length > 0
          )) ||
        []
      const socialKeys =
        (Social &&
          Object.keys(Social)?.filter((item) => Social[item]?.length > 0)) ||
        []
      const emotionalKeys =
        (Emotional &&
          Object.keys(Emotional)?.filter(
            (item) => Emotional[item]?.length > 0
          )) ||
        []
      let wellbeingTabs = []
      if (physicalKeys.length > 0) {
        wellbeingTabs.push('Physical Wellbeing')
      }
      if (socialKeys.length > 0) {
        wellbeingTabs.push('Social Wellbeing')
      }
      if (emotionalKeys.length > 0) {
        wellbeingTabs.push('Emotional Wellbeing')
      }
      wellbeingTabs.push('Other Guidelines')
      state.tabs = wellbeingTabs
      state.poc_details = poc_details
      state.general_answers = general_answers
      state.score_details = score_details
      state.avatar_details = avatar_details
      state.commitment_details = commitment_details
      state.hyperlinks = hyperlinks
      state.project_info = project_info
    },
    setQuestionId: (state, action) => {
      state.questionId = action.payload
    },
    setPropositionName: (state, action) => {
      state.propositionName = action.payload
    },
    setPocName: (state, action) => {
      state.pocName = action.payload
    },
    setPocDetails: (state, action) => {
      state.poc_details = action.payload
      console.log(action.payload)
    },
    setTypeofennovation: (state, action) => {
      state.typeofennovation = action.payload
    },
  },
})

export const {
  setStep,
  setForm,
  addQuestions,
  setProjectId,
  setPropositionId,
  setPocId,
  setAnswers,
  emptyStore,
  emptyAnswers,
  updateSnackerBar,
  getWellbeingAfterSubmitAnswer,
  getEmotionalWellbeingAfterSubmit,
  setPocResults,
  setQuestionId,
  resumeAnswers,
  setPropositionName,
  setPocName,
  setPocDetails,
  setTypeofennovation,
} = pocSlice.actions

export default pocSlice.reducer
