import Layout from './Components/Layout'
import './App.css'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

export const matomoInstance = createInstance({
  urlBase: 'https://matomoapp01.azurewebsites.net/',
  siteId: process.env.REACT_APP_MATOMO_SITE_ID,
  // userId: userInfo.email, // optional, default value: `undefined`.
  // trackerUrl: 'https://matomoapp01.azurewebsites.net/tracking.php', // optional, default value: `${urlBase}matomo.php`
  // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST',
  },
})

function App(props) {
  return (
    <MatomoProvider value={matomoInstance}>
      <div style={{ fontFamily: 'Mars-Centra', backgroundColor: '#F7F9FE' }}>
        <Layout msalInstance={props.msalInstance} />
      </div>
    </MatomoProvider>
  )
}

export default App
