import React, { useEffect } from 'react'
import classes from './Navbar.module.css'
import { Button } from '@mui/material'
import LogoImg from '../../../../Assets/landingPageLogo.png'
import { useNavigate } from 'react-router-dom'
import { UserDataSave } from '../../UserDataSave'
import { useDispatch } from 'react-redux'
import {
  setIsProposition,
  setUserName,
} from '../../../Redux/slices/projectsSlice'

export default function Navbar() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleBackToProject = () => {
    navigate(`/dashboard`)
  }

  useEffect(() => {
    const username = UserDataSave()
    if (username) {
      dispatch(setUserName(username))
    }
  }, [])

  return (
    <>
      <header className={classes.header}>
        <div
          style={{
            display: 'flex',
            gap: '16px',
            marginLeft: '2rem',
            alignItems: 'center',
          }}
        >
          <div onClick={handleBackToProject} style={{ cursor: 'pointer' }}>
            <img style={{ height: '40px' }} src={LogoImg}></img>
          </div>
        </div>
        <div
          style={{
            marginRight: '3.8rem',
            gap: '24px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={() => navigate('/dashboard')}
            style={{
              padding: '8px 16px 8px 16px',
              borderRadius: '4px',
              gap: '4px',
              background: 'white',
              color: 'rgba(0, 0, 160, 1)',
              border: '1px solid rgba(0, 0, 160, 1)',
              height: '32px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '16px',
              textTransform: 'capitalize',
              fontFamily: 'Mars-Centra',
              fontSize: `${window.screen.width < 1024 ? '12px' : '14px'}`,
            }}
            variant='text'
          >
            My Projects
          </Button>
          <Button
            data-testid='start-new'
            onClick={() => {
              navigate('/proposition')
              dispatch(setIsProposition(true))
            }}
            variant='contained'
            style={{
              padding: '8px 16px 8px 16px',
              borderRadius: '4px',
              height: '32px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '16px',
              textTransform: 'capitalize',
              color: 'white',
              opacity: '0.800000011920929',
              background: '#0000A0',
              fontFamily: 'Mars-Centra',
              fontSize: `${window.screen.width < 1024 ? '12px' : '14px'}`,
            }}
          >
            Start New Assessment
          </Button>
        </div>
      </header>
    </>
  )
}
